import React from 'react'

export default function Utilites() {
  return (
    <>
        <section className="assembled-area bg-black py-5" id="utilities">

            <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                <div className="section-content text-center">
                    <h2>Utilities</h2>
                </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-10">
                <img src="assets/img/utilities.png" alt="" className="img-fluid" />
                </div>
            </div>
            </div>
        </section>
    </>
  )
}
