import React from 'react'

export default function Footer() {
  return (
    <>
    <footer className="footer-area">
    <span className="global-icon my-5"></span>
      <div className="container">
        <div className="row justify-content-center footer-top">
     
          <div className="col-md-9 col-lg-9 mt-5 mt-md-0 text-center text-lg">
            <ul className="footer-social-icons">
              <li>
                <a className="instagram" href=" https://www.instagram.com/MrCleverArt" ><i className="fab fa-instagram"></i></a>
              </li>
              <li>
                <a className="twitter" href="https://twitter.com/MrCleverArt" ><i className="fab fa-twitter"></i></a>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="row justify-content-center footer-bottom">
          <div className="col-md-6 col-lg-6 text-center">
            <p className="copyright">{new Date().getFullYear()} All Rights Reserved CleverVision</p>
          </div>
        </div>
      </div>
  </footer></>
  )
}
