import React from 'react'

export default function FAQ() {
  return (
    <>
    <section className="frequently-ask-q-area bg-black" id="why-omega">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 text-center">
          <div className="section-content">
            <h2>Frequently Ask Questions</h2>
          </div>
        </div>
        <div className="col-lg-8 mt-5">
          <div className="faq-wrapper mt-3">
            <div className="accordion" id="accordionExample">
              <div className="faq accordion-item">
                <h2 className="faq-accordion-header" id="faq-headingOne">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="true" aria-controls="faq-collapseOne">
                    Who Created this Project?
                  </button>
                </h2>
                <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>Don’t get Rug Pulled with Cash Grabs! Go with a Decades Long Doxxed Prolific Artist, Author, Creator & Photographer in Mr Clever Art. </p>
                  </div>
                </div>
              </div>
              <div className="faq accordion-item">
                <h2 className="faq-accordion-header" id="faq-headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="true" aria-controls="faq-collapseTwo">
                    What Blockchain and What Kind of NFT will it be?
                  </button>
                </h2>
                <div id="faq-collapseTwo" className="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>10,000 CleverVision Soup Cans Feeding the Ethereum Blockchain, Forever.</p>
                  </div>
                </div>
              </div>
              <div className="faq accordion-item">
                <h2 className="faq-accordion-header" id="faq-headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="true" aria-controls="faq-collapseThree">
                    When does the First Project Begin Minting?
                  </button>
                </h2>
                <div id="faq-collapseThree" className="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>Thursday, November 3, 2022 11:00 a.m PST on the Ethereum Blockchain.</p>
                  </div>
                </div>
              </div>
              <div className="faq accordion-item">
                <h2 className="faq-accordion-header" id="faq-headingFour">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="true" aria-controls="faq-collapseFour">
                    When is Reveal and Where Can I Mint the Project?
                  </button>
                </h2>
                <div id="faq-collapseFour" className="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>CleverVision Soup Cans will Remain Unrevealed Until it has Minted Out.  An Estimated Time of 2 Weeks for Full Reveal Will Occur After Minting Out. Each of the Projects will be Minted on MrCleverArt.com</p>
                  </div>
                </div>
              </div>
              <div className="faq accordion-item">
                <h2 className="faq-accordion-header" id="faq-headingSix">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSix" aria-expanded="true" aria-controls="faq-collapseSix">
                    How Much Are the NFTs?
                  </button>
                </h2>
                <div id="faq-collapseSix" className="accordion-collapse collapse" aria-labelledby="faq-headingSix" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>0.003 ETH per NFT.</p>
                  </div>
                </div>
              </div>
              
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </section></>
  )
}
