/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Hero = () => {
  

    return (
      <div>
        <section className="hero-area bg-cover" id="hero-section" style={{backgroundImage:"url(./assets/img/web-bg-1_2.png)"}} >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-area-content">
                  <a href="#utilities" className="scroll-down-btn">
                    <img src="assets/img/angle-down-img.png" alt=""/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
};

export default Hero;