import React from 'react'

export default function Roadmap() {
  return (
    <>
    <section className="roadmap-area bg-black py-5" id="roadmap">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="section-content text-center">
            <h2>ROADMAP</h2>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <img src="assets/img/roadmap.png" alt="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
    </>
  )
}
