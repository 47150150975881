/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import styled from "styled-components";


export const ConnectButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #662d91;
  padding: 10px;
  font-weight: bold;
  color: #fff;
  width: 150px;
  margin: 0 10px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px #00a751;
  -webkit-box-shadow: 0px 6px 0px -2px #00a751;
  -moz-box-shadow: 0px 6px 0px -2px #00a751;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

const Header = ( ) => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [stickyNav, setStickyNav] = useState(false);

  const setFixed =()=>{
    if(window.scrollY>=392){
      setStickyNav(true)
    }else{
      setStickyNav(false)
    }
  }
  window.addEventListener("scroll", setFixed)

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
        dispatch(fetchData(blockchain.account));
    }
};

async function isConnected() {
  const accounts = await window.ethereum.request({method: 'eth_accounts'});       
  if (accounts.length) {
     dispatch(connect());
  } else {
    //  console.log("Metamask is not connected");
  }
}

useEffect(() => {
  isConnected();
}, []);


useEffect(() => {
  getData();
}, [blockchain.account]);
  return (
    <div>
      <header className={stickyNav?'fixed-top':'navbar_top'}>
       
       <nav className= 'navbar navbar-expand-xl navbar-dark'>
          <div className="container xxl align-items-center">
            <div className="header-left d-xl-none">
              <a className="navbar-brand site-logo" href="index.html">
                <img src="assets/img/site-logo.png" alt="site-logo" />
              </a>
              <div className="d-flex align-items-center  d-none d-xl-block">
              {blockchain.account === "" ||
                                    blockchain.smartContract === null ? (
              <ConnectButton
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                    }}
                >
                    CONNECT
              </ConnectButton>
                                    ):null}
                <div className="social-icons">
  
                  <a href="https://twitter.com/MrCleverArt" className="twitter">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a className="instagram" href="https://www.instagram.com/MrCleverArt" ><i className="fab fa-instagram"></i></a>
                </div>
              </div>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav main-menu pt-4 pt-lg-0 mb-2 mb-lg-0">
                <li className="nav-item active">
                  <a href="#hero-section">Home</a>

                </li>
                {/* <li className="nav-item">
                  <a href="#strange_new_world_area">Story</a>
                </li> */}
                <li className="nav-item">
                  <a href="#utilities">Utilities</a>
                </li>
                <li className="nav-item">
                  <a href="#roadmap">Roadmap</a>
                </li>
                <li className="nav-item">
                  <a href="#project">Project</a>
                </li>
                <li className="nav-item">
                  <a href="#Mint">Mint</a>
                </li>
                <li className="nav-item">
                  <a href="#why-omega">FAQ</a>
                </li>
                <li className="d-xl-none">
                  <div className="d-flex align-items-center mt-3">
                  {blockchain.account === "" ||
                                    blockchain.smartContract === null ? (
              <ConnectButton
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                    }}
                >
                    CONNECT
              </ConnectButton>
                                    ):null}
                    <div className="social-icons">
                      <a href="https://www.instagram.com/MrCleverArt" >
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="https://twitter.com/MrCleverArt">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="header-right d-xl-block">
              <a className="navbar-brand site-logo" href="index.html">
                <img src="assets/img/site-logo.png" alt="site-logo" />
              </a>
              <div className="d-flex align-items-center">
                
                <div className="social-icons">
                <a className="instagram" href="https://www.instagram.com/MrCleverArt" ><i className="fab fa-instagram"></i></a>
                  <a href="https://twitter.com/MrCleverArt" className="twitter">
                    <i className="fab fa-twitter"></i>
                  </a>
                </div>
                {blockchain.account === "" ||
                                    blockchain.smartContract === null ? (
              <ConnectButton
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                    }}
                >
                    CONNECT
              </ConnectButton>
                                    ):null}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;