import React, {useEffect} from 'react';
import jQuery from 'jquery';
import BackToTop from './BackToTop/BackToTop';
import Header from './component/Header/Header';
import Utilites from './component/Utilites/Utilites';
import Roadmap from './component/Roadmap/Roadmap';
import Project from './component/Project/Project';
import Footer from './component/Footer/Footer';
import FAQ from './component/FAQ/FAQ';
import Hero from './component/Hero/Hero';
import Mint from './component/Mint/Mint';

const App = () => {

    useEffect(() =>{
        (function ($) {
            $('#navbar_top .main-menu a').on('click', function () {
                $('#navbar_top .main-menu').find('li.active').removeClass('active');
                $(this).parent('li').addClass('active');
            });
            /**
             * Back To TOP
             */

            var backtotop = $('#back_to_top');

            $(window).scroll(function () {
                if ($(window).scrollTop() > 300) {
                    backtotop.addClass('show');
                } else {
                    backtotop.removeClass('show');
                }
            });

            backtotop.on('click', function (e) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: 0
                }, '300');
            });

            $(document).on('click', '.navbar-toggler', function (e) {
                $(this).toggleClass('open');
                $('header').toggleClass('show')
            })

            const scrollTop = $(window).scrollTop();
            if (scrollTop > 250) {
                $('#navbar_top').addClass('fixed-top');
            } else {
                $('#navbar_top').removeClass('fixed-top');
            }

        })(jQuery);
    });

    return (
        <div>
            <Header/>
            <Hero/>
            <Utilites/>
            <Roadmap/>
            <Project/>
            <Mint/>
            <FAQ/>
            <Footer/>
           <BackToTop/>
        </div>
    );
};

export default App;