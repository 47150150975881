import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../redux/data/dataActions";
import styled from "styled-components";

// Used for wrapping a page component


// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
    display: flex;
    flex: ${({ flex }) => (flex ? flex : 0)};
    flex-direction: ${({ fd }) => (fd ? fd : "column")};
    justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
    align-items: ${({ ai }) => (ai ? ai : "flex-start")};
    background-color: ${({ test }) => (test ? "pink" : "none")};
    width: 100%;
    background-size: cover;
    background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const StyledButton = styled.button`
    padding: 10px;
    border-radius: 50px;
    border: none;
    background-color: #662d91;
    padding: 10px;
    font-weight: bold;
    color: #fff;
    width: 150px;
    margin: 0 10px;
    cursor: pointer;
    box-shadow: 0px 6px 0px -2px #00a751;
    -webkit-box-shadow: 0px 6px 0px -2px #00a751;
    -moz-box-shadow: 0px 6px 0px -2px #00a751;
    :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    }
`;

//  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
//  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
//  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
export const StyledRoundButton = styled.button`

  border: none;
  background-color: var(--primary-text);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--accent-text);

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledMaxButton = styled.button`
  border: none;
  background-color: var(--accent-text);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {

    let Price = 0.003;
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(``);
    const [mintAmount, setMintAmount] = useState(1);
    const [totalCost, setTotalCost] = useState(0.003);


    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
    });


    const claimNFTs = async () => {


        let publicCost = await blockchain.smartContract.publicCost();

        let totalCostWei;
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
            
                totalCostWei = String(publicCost * mintAmount);
            
            try {
                let TX = await blockchain.smartContract
                    .mint(mintAmount , {value:totalCostWei});
                console.log(TX);
                let Res = await TX.wait(1);
                console.log(Res);
                setFeedback(
                    `Your ${CONFIG.NFT_NAME} NFT is Minted Successfully`
                );
                setClaimingNft(false);
            } catch (error) {
                console.log(error.code);
                if (error.code === -32603) {
                    setFeedback(error.data.message.slice(20));
                } else if (error.code === -32000 || error.code === 'INSUFFICIENT_FUNDS') {
                    setFeedback("Insufficient Funds.");
                } else if (error.code === 4001) {
                    setFeedback("Mint Canceled , Please Try Again.");
                } else {
                    setFeedback("Something Went Wrong , Please Try Again.");
                }
                setClaimingNft(false);
            }

    };


    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
        let Tot = newMintAmount * Price;
        setTotalCost(Tot.toFixed(3))
    };

    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 10) {
            newMintAmount = 10;
        }
        setMintAmount(newMintAmount);
            let Tot = newMintAmount * Price;
        setTotalCost(Tot.toFixed(3))

    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    

    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };



    useEffect(() => {
        getConfig();
    }, []);

    return (

            <Container id="Mint" style={{ backgroundColor: '#050707' , color: 'white' }}>
                <SpacerSmall />
                
                <ResponsiveWrapper flex={1} style={{ alignItems: "center" , justifyContent: 'center' , marginTop: '90px'}}>

                    <Container
                    
                        flex={2}
                        jc={"center"}
                        ai={"center"}
                        style={{
                            backgroundColor: "#11131f",
                            padding: 24,
                            borderRadius: 50,
                            maxWidth: "543px",
                            // border: "4px dashed var(--secondary)",
                            boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                        }}
                    >
                        <TextTitle
                            style={{
                                textAlign: "center",
                                fontSize: 20,
                                fontWeight: "500",
                                color: "var(--accent-text)",
                            }}
                        >
                           Mint CleverVision
                        </TextTitle>
                        <TextDescription
                            style={{
                                textAlign: "center",
                                fontSize: 20,
                                color: "var(--secondary-text)",
                            }}
                        >
                            Supply is 10,000 NFTS
                        </TextDescription>
                        <SpacerSmall />

                        <TextDescription
                            style={{
                                textAlign: "center",
                                fontSize: 15,
                                color: "var(--secondary-text)",
                            }}
                        >
                            0.003 ETH Per NFT
                        </TextDescription>
                        
                        <SpacerSmall />
                        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                            <>
                                <TextTitle
                                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                                >
                                    The sale has ended.
                                </TextTitle>
                                <TextDescription
                                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                                >
                                    You can still find {CONFIG.NFT_NAME} on
                                </TextDescription>
                                <SpacerSmall />
                                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                                    {CONFIG.MARKETPLACE}
                                </StyledLink>
                            </>
                        ) : (
                            <>
                                    <>
                                        {blockchain.errorMsg !== "" ? (
                                            <>
                                                <SpacerSmall />
                                                <TextDescription
                                                    style={{
                                                        textAlign: "center",
                                                        color: "var(--accent-text)",
                                                    }}
                                                >
                                                    {blockchain.errorMsg}
                                                </TextDescription>
                                            </>
                                        ) : null}
                                        <TextDescription
                                            style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                            }}
                                        >
                                            {feedback}
                                        </TextDescription>
                                        <SpacerMedium />
                                        <SpacerLarge />
                                        <SpacerLarge />

                                        <Container style={{ 
                                            justifyContent: 'space-between',
                                            borderBottom: '5px solid var(--accent-text)'
                                         }} ai={"center"} jc={"center"} fd={"row"}>
                                            <>
                                                <TextDescription
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: 20,
                                                        color: "var(--accent-text)",
                                                    }}
                                                >
                                                    AMOUNT
                                                </TextDescription> 

                                                    <>
                                                <StyledRoundButton
                                                    style={{ lineHeight: 0.4,fontSize: 20, alignItems : 'baseline' }}
                                                    disabled={claimingNft ? 1 : 0}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        decrementMintAmount();
                                                    }}
                                                >
                                                    -
                                                </StyledRoundButton>

                                                <TextDescription
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: 20,
                                                        color: "var(--accent-text)",
                                                    }}
                                                >
                                                    {mintAmount}
                                                </TextDescription>

                                                <StyledRoundButton
                                                style={{ fontSize: 20, }}
                                                    disabled={claimingNft ? 1 : 0}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        incrementMintAmount();
                                                    }}
                                                >
                                                    +
                                                </StyledRoundButton>
                                                </>
                                            </>

                                                    <SpacerLarge/>
                                                    <SpacerSmall/>
                                            {/* <StyledMaxButton
                                                style={{ lineHeight: 0.4,fontSize: 20, }}
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    maxAmount();
                                                }}
                                            >
                                                MAX
                                            </StyledMaxButton> */}
                                        </Container>
                                        <SpacerSmall />
                                        <Container style={{ 
                                            justifyContent: 'space-between',
                                            borderBottom: '5px solid var(--accent-text)'
                                         }} ai={"center"} jc={"center"} fd={"row"}>
                                                <TextDescription
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: 20,
                                                        color: "var(--accent-text)",
                                                    }}
                                                >
                                                    TOTAL
                                                </TextDescription>           
                                                <TextDescription
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: 20,
                                                        color: "var(--accent-text)",
                                                    }}
                                                >
                                                    {totalCost} ETH
                                                </TextDescription> 
                                        </Container>
                                        <SpacerSmall />
                                        <SpacerSmall />
                                        <Container ai={"center"} jc={"center"} fd={"row"}>
                                        {blockchain.account === "" ||
                                    blockchain.smartContract === null ? (
                                            <StyledButton
                                                style={{ 
                                                    fontSize: '30px',
                                                    width: '150px',

                                                 }}
                                                disabled={0}
                                                onClick={(e) => {
                                                    alert("Please Connect Your Metamask Wallet")
                                                }}
                                            >
                                                {claimingNft ? "BUSY" : "MINT"}
                                            </StyledButton>
                                    ):<StyledButton
                                    style={{ 
                                        fontSize: '30px',
                                        width: '150px',

                                     }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTs();
                                        getData();
                                    }}
                                >
                                    {claimingNft ? "BUSY" : "MINT"}
                                </StyledButton>}
                                        </Container>
                                        <SpacerMedium />

                                        <TextDescription
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: 15,
                                                        color: "#bbbbbb",
                                                    }}
                                                >
                                                    MAKE SURE YOUR METAMASK IS CONNECTED
                                        </TextDescription> 
                                    </>
                                {/* )} */}
                            </>
                        )}
                        <SpacerMedium />
                        
                    </Container>
  

                    
                </ResponsiveWrapper>
                <SpacerMedium />
                
               
           
            </Container>

    );
}

export default App;
